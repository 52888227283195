import { toast } from "react-toastify";
import { firestoreDB } from "./config";
import {
  getAuth,
  deleteUser,
  signOut,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  where,
  deleteDoc,
  query,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";

const db = firestoreDB;
const storage = getStorage();
const auth = getAuth();
const user = auth.currentUser;

/**
 *
 * ? DATABASE FUNCTIONS ?
 *
 */

// * USER FUNCTIONS *

/**
 * Function to get the extra info related to an user
 * @returns object containing info about the user logged
 */
const getUserExtraInfo = async () => {
  const docRef = doc(db, "account-EXTRA-INFO", auth.currentUser.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    toast.error("No such document!");
  }
};

const getUserExtraInfoInitial = async () => {
  const docRef = doc(db, "account-EXTRA-INFO", auth.currentUser.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else { 
    return null;
  }
};


/**
 *
 * @param {object} extraInfo object containing extra information about the user
 */

const setUserExtraInfo = async (extraInfo) => {
  const docRef = doc(db, "account-EXTRA-INFO", auth.currentUser.uid);

  await setDoc(docRef, extraInfo)
    .then(() => toast.success("account-EXTRA-INFO Created!"))
    .catch((err) => toast.error(err));
};


/**
 *
 * @param {object} user
 */
const removeUser = async (user) => {
  deleteUser(user)
    .then(() => {
      toast.success("User removed successfully");
    })
    .catch((error) => {
      toast.error("User cannot be removed");
    });
};

/**
 *  function that execute logOut
 */
const logout = async () => {
  return signOut(auth);
};
/**
 *  function that execute Send verification email
 */
const sendVerificationEmail = async () => {
  return sendEmailVerification(auth.currentUser)
    .then(() => toast.success("Email verification sent, check your inbox."))
    .catch((e) => {
      toast.error("An error occurred: " + e);
    });
};

// * ORGANIZATION FUNCTIONS *

/**
 * method to create an organization
 * @param {object} organizationInfo contains all the information to create an organization
 * @returns id of the organization created
 */
const createOrganization = async (organizationInfo) => {
  const organizationId = await addDoc(
    collection(firestoreDB, "organizations"),
    organizationInfo
  )
    .then((docRef) => {
      toast.success("Organization added successfully");
      return docRef.id;
    })
    .catch((err) => {
      toast.error(err);
    });
  return organizationId;
};

/**
 *
 * @returns
 */
const getOrganizationInfo = async (organizationId) => {
  if (organizationId) {
    const docRef = doc(db, "organizations", organizationId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
  }
};

/**
 *
 * @param {*} organizationInfo
 */
const setOrganizationInfo = async (organizationInfo, organizationId) => {
  const docRef = doc(db, "organizations", organizationId);
  await updateDoc(docRef, organizationInfo)
    .then(() => toast.success("organization: " + organizationId + " Updated!"))
    .catch((err) => toast.error(err));
};

/**
 *
 * @param {string} logoUrl
 */
const updateOrganizationLogo = async (logoUrl, organizationId) => {
  const oRef = doc(db, "organizations", organizationId);
  await updateDoc(oRef, { logoUrl: logoUrl })
    .then(() => { })
    .catch((e) => {
      toast.error("Organization Logo not updated: " + e);
    });
};

// * ACCOMODATION FUNCTIONS *

/**
 * Add a new accomodation related to the organization
 * @param {object} accomodationInfo object containing the accomodation info
 * @returns the accomodation object that has been created
 */
const createAccomodation = async (accomodationInfo, organizationId) => {
  const accRef = collection(
    db,
    "organizations",
    organizationId,
    "accomodations"
  );
  await addDoc(accRef, accomodationInfo)
    .then((accomodationRef) => {
      toast.success("Accommodation added successfully");
      return accomodationRef;
    })
    .catch((e) => {
      toast.error(e);
    });
};


/**
 * return all accomodation related to an organization
 * @returns list containing all the accomodation object related to the organization
 */
const getAccomodationList = async (organizationId) => {
  const docRef = collection(
    db,
    "organizations",
    organizationId,
    "accomodations"
  );
  let accomodationsArray = [];
  const snapshot = await getDocs(docRef);
  snapshot.forEach((doc) => {
    accomodationsArray.push({ data: doc.data(), id: doc.id });
  });
  return accomodationsArray;
};

/**
 * return a specific Accomodation
 * @param {string} accomodationId Id of the accomodation
 * @returns a specific Accomodation data
 */
const getAccomodationbyId = async (accomodationId, organizationId) => {
  const accRef = doc(
    db,
    "organizations",
    organizationId,
    "accomodations",
    accomodationId
  );
  await getDoc(accRef)
    .then((acc) => {
      return acc.data();
    })
    .catch((e) => {
      toast.error("error: " + e);
    });
};

/**
 * @param {object} accomodationInfo data of the organization to update
 * @param {string} accomodationId Id of the accomodation
 * @returns
 */
const updateAccomodation = async (
  accomodationId,
  accomodationInfo,
  organizationId
) => {
  const docRef = doc(
    db,
    "organizations",
    organizationId,
    "accomodations",
    accomodationId
  );
  return await updateDoc(docRef, accomodationInfo)
    .then(() => {
      toast.success("Accommodation Updated Succesfully");
    })
    .catch(() => {
      toast.error("An Error occurred");
    });
};

/**
 * Deletes an accomodation from the db
 * @param {string} accomodationId Id of the accomodation
 * @returns
 */
const deleteAccomodation = async (accomodationId, organizationId) => {
  const docRef = doc(
    db,
    "organizations",
    organizationId,
    "accomodations",
    accomodationId
  );

  return await deleteDoc(docRef).then(() => {
    toast.success("Accommodation deleted");
  });
};

// * OWNER FUNCTIONS *

/**
 * @param {object} ownerInfo data of the owner to create
 * @returns Id of the created Owner
 */
const createOwner = async (ownerInfo, organizationId) => {
  const docRef = collection(db, "organizations", organizationId, "owners");
  return await addDoc(docRef, ownerInfo)
    .then((el) => {
      toast.success("Owner added succesfully");
      return el.id;
    })
    .catch((err) => toast.error(err));
};

/**
 *
 * @param {*} ownerInfo
 * @param {*} ownerId
 * @returns
 */
const updateOwner = async (ownerInfo, ownerId, organizationId) => {
  const ownerRef = doc(db, "organizations", organizationId, "owners", ownerId);

  await updateDoc(ownerRef, ownerInfo)
    .then((updOwn) => {
      toast.success("Owner updated");
      return updOwn;
    })
    .catch(() => {
      toast.error("Something went wrong");
      return;
    });
};

/**
 * get a specified owner
 * @param {string} ownerId id of the owner to get
 * @returns Owner data
 */
const getOwnerbyId = async (ownerId, organizationId) => {
  const docRef = doc(db, "organizations", organizationId, "owners", ownerId);
  await getDoc(docRef)
    .then((owner) => {
      return owner.data();
    })
    .catch((e) => {
      toast.error(e);
    });
};

/**
 * return a list containing all owners related to the given organization
 * @returns Array containing all the organization owners
 */
const getOwnerList = async (organizationId) => {
  const docRef = collection(db, "organizations", organizationId, "owners");
  let ownerArray = [];
  const snapshot = await getDocs(docRef);
  snapshot.forEach((doc) => {
    ownerArray.push({ data: doc.data(), id: doc.id });
  });
  return ownerArray;
};

/**
 * delete Owner related to an organization
 * @param {string} ownerId id of the owner to delete
 * @returns TRUE if it's deleted
 */
const deleteOwner = async (ownerId, organizationId) => {
  const docRef = doc(db, "organizations", organizationId, "owners", ownerId);

  await deleteDoc(docRef)
    .then(() => {
      return true;
    })
    .catch((e) => {
      toast.error(e);
    });
};

// * BOOKING FUNCTIONS *

/**
 * create a booking related to the accomodation of the organization
 *
 * @param {object} bookingInfo data of the booking to get
 * @param {string} accomodationId accomodation ID related to the  booking
 * @param {string} organizationId organization ID related to the accomodation collection
 * @returns created booking data
 */
const createBooking = async (bookingInfo, organizationId) => {
  const bookingRef = collection(
    db,
    "organizations",
    organizationId,
    "bookings"
  );
  return await addDoc(bookingRef, bookingInfo)
    .then((booking) => {
      return booking.id;
    })
    .catch((e) => {
      toast.error(e);
    });
};

/**
 *
 * @param {object} bookingInfo
 * @param {string} bookingId
 * @param {string} accomodationId
 */
const updateBooking = async (bookingInfo, bookingId, organizationId) => {
  if (!bookingInfo || !organizationId) return;
  let bookingRef = doc(db, "organizations", organizationId, "bookings", bookingId);
  
  await updateDoc(bookingRef, bookingInfo)
    .then((updtBooking) => {
      toast.success("Booking updated");
      // return updtBooking;
    })
    .catch((e) => {
      toast.error(e);
    });
};
// const updateCost = async (costInfo, costId, organizationId) => {
//   if (!costInfo || !organizationId) return;
//   let costRef = doc(db, "organizations", organizationId, "costs", costId);

//   await updateDoc(costRef, costInfo)
//     .then((cost) => {
//       toast.success("Cost updated successfully!");
//     })
//     .catch((e) => {
//       toast.error("Error inserting cost: " + e);
//   });
// };

/**
 * get a single booking
 * @param {string} bookingId Id of the booking to get
 * @param {string} accomodationId accomodation ID related to the  booking
 * @param {string} organizationId organization ID related to the booking collection
 * @returns Booking data
 */
const getBookingbyId = async (bookingId, accomodationId, organizationId) => {
  const bookingRef = doc(
    db,
    "organizations",
    organizationId,
    "accomodations",
    accomodationId,
    "bookings",
    bookingId
  );
  await getDoc(bookingRef)
    .then((booking) => {
      return booking.data();
    })
    .catch((e) => {
      toast.error(e);
    });
};


/**
 * @param {string} accomodationId accomodation ID related to the  booking
 * @param {string} organizationId organization ID related to the booking collection
 * @returns
 */
const getBookingList = async (organizationId) => {
  const bookingListRef = collection(
    db,
    "organizations",
    organizationId,
    "bookings"
  );
  let bookingArray = Array();
  (await getDocs(bookingListRef)).forEach((booking) => {
    bookingArray.push({ data: booking.data(), id: booking.id });
  });
  return bookingArray;
};

/**
 *
 * @param {string} bookingId
 * @param {string} accomodationId accomodation ID related to the  booking
 * @param {string} organizationId
 * @returns
 */
const deleteBooking = async (bookingId, organizationId) => {
  const bookingRef = doc(
    db,
    "organizations",
    organizationId,
    "bookings",
    bookingId
  );

  await deleteDoc(bookingRef)
    .then(() => {
      toast.success("Booking deleted successfully");
      return true;
    })
    .catch((e) => {
      toast.error(e);
      return false;
    });
};


// * RULE FUNCTIONS *
/**
 * Function to create a new rule
 * @param {object} ruleInfo should contain all the values about the cost object
 * @param {array} ruleRelation  array describing the entity related to the cost ex "organizations", organizationId , "accomodations", accomodationId...
 */
const createRule = async (ruleInfo, organizationId) => {
  if (!ruleInfo || !organizationId) return;
  let ruleRef = collection(db, "organizations", organizationId, "rules");

  await addDoc(ruleRef, ruleInfo)
    .then((rule) => {
      toast.success("Rule added successfully!");
      return rule.id;
    })
    .catch((e) => toast.error("Error inserting rule: " + err));
};

/**
 * Function to create a new rule
 * @param {object} ruleInfo should contain all the values about the cost-rule object
 * @param {array} ruleRelation  array describing the entity related to the cost ex "organizations", organizationId , "accomodations", accomodationId...
 */
const createCostRule = async (costRuleInfo, organizationId) => {
  if (!costRuleInfo || !organizationId) return;
  let ruleRef = collection(db, "organizations", organizationId, "cost-rules");

  await addDoc(ruleRef, costRuleInfo)
    .then((rule) => {
      toast.success("Cost Rule added successfully!");
      return rule.id;
    })
    .catch((e) => toast.error("Error inserting the cost rule: " + err));
};

/**
 *
 * @param {string} organizationId
 * @returns
 */

const getCostRulesList = async (organizationId) => {
  const costrulesListRef = collection(db, "organizations", organizationId, "cost-rules");
  let ruleArray = Array();
  (await getDocs(costrulesListRef)).forEach((rule) => {
    ruleArray.push({ data: rule.data(), id: rule.id });
  });

  return ruleArray;
};

/**
 *
 * @param {*} costId
 * @param {*} organizationId
 * @returns
 */
const deleteCostRule = async (ruleId, organizationId) => {
  const ruleRef = doc(db, "organizations", organizationId, "cost-rules", ruleId);
  try {
    await deleteDoc(ruleRef);
    toast.success("Cost rule deleted successfully!");
    return;
  } catch (e) {
    toast.error("Something went wrong");
    return;
  }
};

/**
 *
 * @param {*} ruleInfo
 * @param {*} ruleId
 * @param {*} ruleRelation
 */
const updateCostRule = async (ruleInfo, ruleId, organizationId) => {
  if (!ruleInfo || !organizationId) return;
  let ruleRef = doc(db, "organizations", organizationId, "cost-rules", ruleId);

  await updateDoc(ruleRef, ruleInfo)
    .then((cost) => {
      toast.success("Cost rule updated successfully!");
    })
    .catch((e) => {
      toast.error("Error while updating rule." + e);
  });
};
/**
 *
 * @param {string} ruleId
 * @param {array} ruleRelation  array describing the entity related to the cost
 * @returns cost data
 */
const getRulebyId = async (ruleId, organizationId) => {
  if (!costId || !organizationId) {
    return;
  }

  let ruleRef = doc(db, "organizations", organizationId, "rulews", ruleRelation);

  return await getDoc(ruleRef)
    .data()
    .catch((e) => {
      toast.error(e);
    });
};


// * COST FUNCTIONS *
/**
 * Function to create a new cost, if the cost is related to the ORGANIZATION entity costRelation should BE NOT VALORIZED
 * @param {object} costInfo should contain all the values about the cost object
 * @param {array} costRelation  array describing the entity related to the cost ex "organizations", organizationId , "accomodations", accomodationId...
 */
const createCost = async (costInfo, organizationId) => {
  if (!costInfo || !organizationId) return;
  let costRef = collection(db, "organizations", organizationId, "costs");

  await addDoc(costRef, costInfo)
    .then((cost) => {
      toast.success("Cost added successfully!");
      return cost.id;
    })
    .catch((e) => toast.error("Error inserting cost: " + err));
};

/**
 *
 * @param {*} costInfo
 * @param {*} costId
 * @param {*} costRelation
 */
const updateCost = async (costInfo, costId, organizationId) => {
  if (!costInfo || !organizationId) return;
  let costRef = doc(db, "organizations", organizationId, "costs", costId);

  await updateDoc(costRef, costInfo)
    .then((cost) => {
      toast.success("Cost updated successfully!");
    })
    .catch((e) => {
      toast.error("Error inserting cost: " + e);
  });
};
/**
 *
 * @param {string} costId
 * @param {array} costRelation  array describing the entity related to the cost
 * @returns cost data
 */
const getCostbyId = async (costId, organizationId) => {
  if (!costId || !organizationId) {
    return;
  }

  let costRef = doc(db, "organizations", organizationId, "costs", costRelation);

  return await getDoc(costRef)
    .data()
    .catch((e) => {
      toast.error(e);
    });
};

/**
 *
 * @param {string} organizationId
 * @returns
 */
const getCostList = async (organizationId) => {
  const costListRef = collection(db, "organizations", organizationId, "costs");
  let costArray = Array();
  (await getDocs(costListRef)).forEach((cost) => {
    costArray.push({ data: cost.data(), id: cost.id });
  });

  return costArray;
};

/**
 *
 * @param {*} costId
 * @param {*} organizationId
 * @returns
 */
const deleteCost = async (costId, organizationId) => {
  const costRef = doc(db, "organizations", organizationId, "costs", costId);
  try {
    await deleteDoc(costRef);
    toast.success("Cost deleted successfully!");
    return;
  } catch (e) {
    toast.error("Something went wrong");
    return;
  }
};

// * SUBSCRIPTION FUNCTIONS *

const setSubscriptionData = async (subscriptionInfo, organizationId) => {
  const docRef = collection(db, "organizations", organizationId);
  await updateDoc(docRef, subscriptionInfo)
    .then((subscriptionInfo) => {
      toast.success("Subscription created successfully!");
      return subscriptionInfo.id;
    })
    .catch((e) => toast.error("Error creating subscription: " + e));
};

const getSubscriptionData = async (organizationId) => {
  const subscriptionRef = collection(db, "organizations", organizationId, organizationId.subscription);
  let subscription = await getDocs(subscriptionRef);
  return subscription;
}

const updateSubscriptionData = async (subscriptionInfo, organizationId) => {

  const docRef = doc(
    db,
    "organizations",
    organizationId
  );

  await updateDoc(docRef, subscriptionInfo)
    .then(() => {
      toast.success("Subscription updated!");
    })
    .catch((e) => {
      toast.error(e);
    });
};

const deleteSubscriptionData = async (subscriptionInfo, organizationId) => {
  const subscriptionRef = doc(
    db,
    "organizations",
    organizationId,
    organizationId.subscription
  );
  try {
    await deleteDoc(subscriptionRef);
    toast.success("Subscription deleted successfully!");
    return;
  } catch (e) {
    toast.error("Something went wrong.");
    return;
  }
};
  

// * SUPPLIER FUNCTIONS *

/**
 *
 * @param {*} supplierInfo
 * @returns
 */
const setSupplier = async (supplierInfo, organizationId) => {
  const docRef = collection(db, "organizations", organizationId, "suppliers");
  await addDoc(docRef, supplierInfo)
    .then((supplier) => {
      toast.success("Supplier added successfully!");
      return supplier.id;
    })
    .catch((e) => toast.error("Error inserting supplier: " + err));
};

const updateSupplier = async (supplierInfo, supplierId, organizationId) => {
  const supRef = doc(
    db,
    "organizations",
    organizationId,
    "suppliers",
    supplierId
  );

  await updateDoc(supRef, supplierInfo)
    .then((updtSupp) => {
      toast.success("Supplier updated");
      return updtSupp;
    })
    .catch((e) => {
      toast.error(e);
      return;
    });
};
/**
 *
 * @param {string} supplierId
 * @returns
 */
const getSupplierById = async (supplierId, organizationId) => {
  if (!supplierId) {
    return;
  }
  const docRef = doc(
    db,
    "organizations",
    organizationId,
    "suppliers",
    supplierId
  );
  return await getDoc(docRef).data();
};

/**
 *
 * @returns
 */
const getSupplierList = async (organizationId) => {
  const sListRef = collection(db, "organizations", organizationId, "suppliers");
  let sArray = Array();
  (await getDocs(sListRef)).forEach((s) => {
    sArray.push({ data: s.data(), id: s.id });
  });
  return sArray;
};

/**
 *
 * @param {*} supplierId
 * @returns
 */


const deleteSupplier = async (supplierId, organizationId) => {
  const supplierRef = doc(
    db,
    "organizations",
    organizationId,
    "suppliers",
    supplierId
  );
  try {
    await deleteDoc(supplierRef);
    toast.success("Cost deleted successfully!");
    return;
  } catch (e) {
    toast.error("Something went wrong");
    return;
  }
};


// BOOKING-PROVIDER
const setBookingProvider = async (providerInfo, organizationId) => {
  const docRef = collection(db, "organizations", organizationId, "booking-providers");
  await addDoc(docRef, providerInfo)
    .then((providerInfo) => {
      toast.success("Booking provider added successfully!");
      return providerInfo.id;
    })
    .catch((e) => toast.error("Error inserting booking provider: " + err));
};

const getBookingProviderList = async (organizationId) => {
  const bpListRef = collection(db, "organizations", organizationId, "booking-providers");
  let bpArray = Array();
  (await getDocs(bpListRef)).forEach((bp) => {
    bpArray.push({ data: bp.data(), id: bp.id });
  });
  return bpArray;
};

const updateBookingProvider = async (bookingProviderInfo, bookingProviderId, organizationId) => {
  const bpRef = doc(
    db,
    "organizations",
    organizationId,
    "booking-providers",
    bookingProviderId
  );

  await updateDoc(bpRef, bookingProviderInfo)
    .then((updtBp) => {
      toast.success("Ota Commission updated");
      return updtBp;
    })
    .catch((e) => {
      toast.error(e);
      return;
    });
};


const deleteBookingProvider = async (bookingProviderId, organizationId) => {
  const bpRef = doc(
    db,
    "organizations",
    organizationId,
    "booking-providers",
    bookingProviderId
  );
  try {
    await deleteDoc(bpRef);
    toast.success("Ota Commission successfully!");
    return;
  } catch (e) {
    toast.error("Something went wrong");
    return;
  }
};

/**
 *
 * ? STORAGE FUNCTIONS ?
 *
 */

/**
 *
 * @param {*} imageUpload
 * @returns
 */
const uploadUserImage = async (imageUpload) => {
  if (imageUpload == null) {
    toast.error("You must select a valid image to upload");
    return;
  }
  const imageRef = ref(
    storage,
    `profilePic/${auth.currentUser.uid}-profilepic`
  );
  await uploadBytes(imageRef, imageUpload)
    .then(() => {
      toast.success("Profile picture uploaded successfully!");
      updateProfile(user, {
        photoURL: `profilePics/${auth.currentUser.uid}-profilepic`,
      });
    })
    .catch(() => {
      toast.error("Something went wrong with uploading your profile picture");
    });
};

/**
 *
 * @param {*} imageUpload
 * @param {string} organizationId
 * @returns
 */
const uploadOrganizationImage = (imageUpload, organizationId) => {
  if (imageUpload == null) {
    toast.error("You must select a valid image to upload");
    return;
  }
  const imageRef = ref(storage, `organizationLogo/${organizationId}-logo`);
  uploadBytes(imageRef, imageUpload)
    .then(() => {
      toast.success("Organization logo uploaded successfully!");
      updateOrganizationLogo(
        `organizationsLogo/${organizationId}-logo`,
        organizationId
      );
    })
    .catch(() => {
      toast.error("Something went wrong with uploading your organization logo");
    });
};

/**
 *
 * @param {*} fileUrl
 */
const deleteFile = async (fileUrl) => {
  try {
    const fileRef = ref(storage, fileUrl);
    await deleteObject(fileRef).then(() => {
      toast.success("File deleted successfully");
    });
  } catch (e) {
    toast.error("Error deleting file");
  }
};

export {
  // USER
  getUserExtraInfo,
  getUserExtraInfoInitial,
  setUserExtraInfo,
  sendVerificationEmail,
  removeUser,
  logout,
  // ORGANIZATION
  getOrganizationInfo,
  setOrganizationInfo,
  createOrganization,
  // ACCOMODATION
  createAccomodation,
  getAccomodationList,
  getAccomodationbyId,
  updateAccomodation,
  deleteAccomodation,
  // OWNER
  createOwner,
  updateOwner,
  getOwnerList,
  getOwnerbyId,
  deleteOwner,
  // BOOKING
  createBooking,
  updateBooking,
  getBookingbyId,
  getBookingList,
  deleteBooking,
  // COST
  createCost,
  updateCost,
  getCostbyId,
  getCostList,
  deleteCost,
  // SUPPLIER
  setSupplier,
  updateSupplier,
  getSupplierById,
  getSupplierList,
  deleteSupplier,
  // BOOKING-PROVIDER
  setBookingProvider,
  updateBookingProvider,
  deleteBookingProvider,  
  getBookingProviderList,
  // SUBSCRIPTION
  setSubscriptionData,
  getSubscriptionData,
  updateSubscriptionData,
  deleteSubscriptionData,
  // STORAGE
  uploadUserImage,
  uploadOrganizationImage,
  deleteFile,
  // COST_RULES
  createCostRule,
  getCostRulesList,
  deleteCostRule,
  updateCostRule
};
