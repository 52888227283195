import { fromUnixTime } from "date-fns";
import React, { useEffect, useState } from "react";
import SecondaryButton from "../../components/buttons/secondary-button";
import TertiaryButton from "../../components/buttons/tertiary-button";
import { NewDatePicker } from "../../components/input/fields/date-pickers/newDatePicker";
import TextField from "../../components/input/fields/text-field";
import { UnitField } from "../../components/input/fields/unit-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { updateOrganization } from "../../utilities/functions";
import { toast } from "react-toastify";
import { format, formatISO, formatDistance, differenceInDays } from "date-fns";
import { it } from 'date-fns/locale';


const UserManager = ({ userInfo, onComplete, onClose }) => {
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(userInfo.subscription.plan);
  const [powerBiDataSetId, setPowerBiDataSetId] = useState(userInfo.powerBiDataSetId);
  const [powerBiReportId, setPowerBiReportId] = useState(userInfo.powerBiReportId);
  const [powerBiWorkspaceId, setPowerBiWorkspaceId] = useState(userInfo.powerBiWorkspaceId);
  const [amount, setAmount] = useState(userInfo.subscription.amount);
  const [amountExtra, setAmountExtra] = useState(userInfo.subscription.amountExtra);
  const [expired, setExpired] = useState(fromUnixTime(userInfo.subscription.expired._seconds));
  const [properties, setProperties] = useState(userInfo.subscription.properties);
  const [recurrency, setRecurrency] = useState(userInfo.subscription.recurrency);
  const [propertiesExtra, setPropertiesExtra] = useState(userInfo.subscription.propertiesExtra);
  // const expireDate = fromUnixTime(userInfo.subscription.expired._seconds);


  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const typeSubscriptionPlan = [
    {
      value: "propertyManager",
      label: "Property Manager",
    },
    {
      value: "host",
      label: "Host"
    }
  ];

  // useEffect(() => {
  //   setSelectedSubscriptionPlan(userInfo.subscription.plan || "");
  // }, [userInfo.subscription.plan]);

  return (
    <div className="w-full max-w-5xl p-5">

      <div className="flex flex-col justify-between ml-auto mr-auto h-fit ">
        <div className="flex items-center mb-4">
          <CurrencyField
            label={"Amount"}
            placeholder={amount}
            value={amount}
            onChange={(el) => setAmount(Number(el.target.value))}
            inputClassName={"p-1 max-w-[100px]"}
          />
          <CurrencyField
            label={"Amount per property"}
            placeholder={amountExtra}
            value={amountExtra}
            onChange={(el) => setAmountExtra(Number(el.target.value))}
            inputClassName={"p-1 max-w-[100px]"}
          />


          <UnitField
            label={"Number of Properties"}
            placeholder={properties}
            value={properties}
            onChange={(el) => setProperties(Number(el.target.value))}
            inputClassName={" p-1 max-w-[100px]"}
          />
        </div>
        <div className="flex items-center mb-4">
          <SelectMenu
            label={"Subscription plan"}
            options={typeSubscriptionPlan}
            className={"w-[350px]"}
            value={typeSubscriptionPlan.find((item) => item.value === selectedSubscriptionPlan)}
            onChange={(el) => {
              setSelectedSubscriptionPlan(el ? el.value : "");
            }}
          />
          <NewDatePicker
            label={"Subscription end"}
            className={"ml-[50px] w-[300px]"}
            defaultValue={expired}
            onCompleted={(el) => setExpired(el)}
          />
        </div>
        <div className="flex items-center mb-4">
          <TextField
            label={"PowerBi DataSet ID"}
            inputClassName={"p-1 w-[350px]"}
            onChange={(e) => setPowerBiDataSetId(e.target.value)}
            defaultValue={powerBiDataSetId}
            placeholder={"Insert PowerBi DataSet ID"}

          />
          <TextField
            label={"PowerBi Report ID"}
            inputClassName={"p-1 w-[350px]"}
            onChange={(e) => setPowerBiReportId(e.target.value)}
            defaultValue={powerBiReportId}
            placeholder={"Insert PowerBi Report ID"}

          />
        </div>
        <TextField
          label={"PowerBi Workspace ID"}
          inputClassName={"p-1 w-[350px]"}
          onChange={(e) => setPowerBiWorkspaceId(e.target.value)}
          defaultValue={powerBiWorkspaceId}
          placeholder={"Insert PowerBi Workspace ID"}

        />
        <div className="flex items-center justify-end mb-4 mt-4">
          <TertiaryButton
            onClick={onClose}
            className={"bg-red p-2 w-fit mr-5"}
            content={"Close"}
          />
          <SecondaryButton
            className={"p-2 h-auto w-fit"}
            content={"Update Subscription"}
            onClick={() => {

              const subscriptionValues = {
                powerBiDataSetId: powerBiDataSetId,
                powerBiReportId: powerBiReportId,
                powerBiWorkspaceId: powerBiWorkspaceId,
                subscription: {
                  amount: amount,
                  expired: expired,
                  plan: selectedSubscriptionPlan,
                  properties: properties,
                  recurrency: recurrency,
                  amountExtra: amountExtra,
                  propertiesExtra: propertiesExtra,
                },
              }

              updateOrganization(userInfo.organizationId, subscriptionValues).then(value => {
                onComplete();
              });
            }}
          />


        </div>
      </div>
    </div>

  );
};

export default UserManager;