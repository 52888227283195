import { format, fromUnixTime } from "date-fns";
import React from "react";
import TertiaryButton from "../../components/buttons/tertiary-button";

const UserView = ({ selectedUser, onClose }) => {
let formattedData = null;
  if(selectedUser && selectedUser.subscription){
    const data = fromUnixTime(selectedUser.subscription.expired._seconds);
    formattedData = format(data, "dd MMM yyyy ");
  }


  function formatNumberWithComma(number) {
    if (number == null || number == undefined) {
      return 0;
    }
    return number.toString().replace('.', ',');
  }

  return (
    <>
      <div className="text-base">
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Organization:</h4>
          <p> {selectedUser?.organizationName ?? ""}</p>
        </div>
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Subscription plan:  </h4>
          <p> {selectedUser.subscription.plan ?? ""}</p>
        </div>
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Subscription expires:  </h4>
          <p> {formattedData ?? ""}</p>
        </div>
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Properties:  </h4>
          <p> {selectedUser.subscription.properties ?? ""}</p>
        </div>
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Price per property:  </h4>
          <p> {selectedUser.subscription.amountExtra ?? ""}</p>
        </div>
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">Amount: </h4>
          <p>{selectedUser.subscription.amount ? "€ " + formatNumberWithComma(selectedUser.subscription.amount) : ""  ?? ""}</p>
        </div>
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">PowerBi DataSet ID: </h4>
          <p>  {selectedUser.powerBiDataSetId ?? ""}</p>
        </div>
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">PowerBi Report ID: </h4>
          <p>  {selectedUser.powerBiReportId ?? ""}</p>
        </div>
        <div className="flex items-center space-x-2">
          <h4 className="font-bold">PowerBi WorkSpace ID: </h4>
          <p>  {selectedUser.powerBiWorkspaceId ?? ""}</p>
        </div>

      </div>
      <div className="flex justify-end">
        <TertiaryButton
          content={"Close"}
          className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default UserView;
