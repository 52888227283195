import React, { useContext, useEffect, useState } from "react";
import Modal from "../dialogs/dialog";
import UserView from "../../pages/users/view";
import PrimaryButton from "../buttons/primary-button";
import SecondaryButton from "../buttons/secondary-button";
import TertiaryButton from "../buttons/tertiary-button";
import { toast } from "react-toastify";
import { getOrganizationById, deleteUser, changeStatus } from "../../utilities/functions";
import { DataContext } from "../../pages/index";
import { IoPencil, IoTrashBin } from "react-icons/io5";
import UserManager from "../../pages/users/edit";

const UserRow = ({ user }) => {

  const creationTime = new Date(user.creationTime).toLocaleString();
  const lastSignInTime = new Date(user.lastSignInTime).toLocaleString();
    //const expiredDate = new Date ( 1000 * user.subscription.expired)
  const [userSelected, setUserSelected] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [disableModal, setDisableModal] = useState();
  const [idUser, setIdUser] = useState(user.uid);
  const [isDisabled, setIsDisabled] = useState(user.disabled);
  const [userToModify, setUserToModify] = useState();
  const [removeModal, setRemoveModal] = useState();
  const context = useContext(DataContext);

  const showUserInfo = () => {
    setViewModal(true);
  }

  return (

    <>
      <tr className="border-b cursor-pointer hover:bg-neutral-100">
        <td
          className="p-2 text-center border-r"
          onClick={() => {
            setUserSelected({
              id: user.organizationId,
              name: user.displayName,
              organizationName: user.organizationName,
              powerBiDataSetId: user.powerBiDataSetId,
              powerBiReportId: user.powerBiReportId,
              powerBiWorkspaceId: user.powerBiWorkspaceId,
              subscription: user.subscription
            });
            showUserInfo();
          }}
        >{user.displayName}</td>
        <td
          onClick={() => {
            setUserSelected({
              id: user.organizationId,
              name: user.displayName,
              organizationName: user.organizationName,
              powerBiDataSetId: user.powerBiDataSetId,
              powerBiReportId: user.powerBiReportId,
              powerBiWorkspaceId: user.powerBiWorkspaceId,
              subscription: user.subscription
            });
            showUserInfo();

          }} className="p-2 text-center border-r">{user.email}</td>
        <td
          onClick={() => {
            setUserSelected({
              id: user.organizationId,
              name: user.displayName,
              organizationName: user.organizationName,
              powerBiDataSetId: user.powerBiDataSetId,
              powerBiReportId: user.powerBiReportId,
              powerBiWorkspaceId: user.powerBiWorkspaceId,
              subscription: user.subscription
            });
            showUserInfo();

          }} className="p-2 text-center border-r">{user.phoneNumber}</td>
        <td
          onClick={() => {
            setUserSelected({
              id: user.organizationId,
              name: user.displayName,
              organizationName: user.organizationName,
              powerBiDataSetId: user.powerBiDataSetId,
              powerBiReportId: user.powerBiReportId,
              powerBiWorkspaceId: user.powerBiWorkspaceId,
              subscription: user.subscription
            });
            showUserInfo();

          }} className="p-2 text-center border-r">{creationTime}</td>
        <td
          onClick={() => {
            setUserSelected({
              id: user.organizationId,
              name: user.displayName,
              organizationName: user.organizationName,
              powerBiDataSetId: user.powerBiDataSetId,
              powerBiReportId: user.powerBiReportId,
              powerBiWorkspaceId: user.powerBiWorkspaceId,
              subscription: user.subscription
            });
            showUserInfo();

          }} className="p-2 text-center border-r">{lastSignInTime}</td>
        <td
          onClick={() => {
            setUserSelected({
              id: user.organizationId,
              name: user.displayName,
              organizationName: user.organizationName,
              powerBiDataSetId: user.powerBiDataSetId,
              powerBiReportId: user.powerBiReportId,
              powerBiWorkspaceId: user.powerBiWorkspaceId,
              subscription: user.subscription
            });
            showUserInfo();

          }} className="p-2 border-r text-center">{user?.organizationName || "N/A"}</td>
        <td className=" p-2">
          <div className="flex items-center justify-center space-x-4">
            <PrimaryButton
              onClick={() => {
                if(user?.organizationName !== ""){
                    getOrganizationById(user?.organizationId).then(response => {
                        setUserToModify(response);
                        setEditModal(true);
                    });
                }else{
                    toast.error("The user doesn't have an organization associated!");
                    return;
                }
              }}
              content={<IoPencil className="mx-auto" />}
              className={"p-1 mx-2 max-w-[100px]"}
            />
            <SecondaryButton
              onClick={() => {
                setUserSelected({
                  id: user.organizationId,
                  name: user.displayName,
                  organizationName: user.organizationName,
                  powerBiDataSetId: user.powerBiDataSetId,
                  powerBiReportId: user.powerBiReportId,
                  powerBiWorkspaceId: user.powerBiWorkspaceId,
                  subscription: user.subscription
                })

                setRemoveModal(true);

              }}
              content={<IoTrashBin className="mx-auto" />}
              className={"p-1 mx-2 max-w-[100px]"}
            />
            <SecondaryButton
              onClick={() => {
                setDisableModal(true);
              }

              }

              content={isDisabled ? "Enable" : "Disable"}
              className={"p-1 mx-2 max-w-[100px]"}
            />

          </div>
        </td>
      </tr>
      <Modal
        externalControl
        title={"Remove " + (user.displayName ? user.displayName : '')}
        body={
          <>
            <h1 className="text-lg">Are you sure to remove this user and all his data?</h1>
            <h2>{ }</h2>
            <div className="flex justify-end">
              <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Cancel"}
                onClick={() => {
                  setRemoveModal(false)
                }}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Yes, I'm sure"}
                onClick={() => {
                  deleteUser(idUser, user.organizationId);
                  context.updateData("users");
                  setRemoveModal(false);
                }}
              />
            </div>
          </>
        }
        status={removeModal}
        onClose={() => setRemoveModal(false)}
      />
      <Modal
        externalControl
        title={"Disable " + (user.displayName ? user.displayName : '')}
        body={
          <>
            {isDisabled == true ? (<h1 className="text-lg">Are you sure to enable this user?</h1>) : <h1 className="text-lg">Are you sure to disable this user?</h1>}

            <h2>{ }</h2>
            <div className="flex justify-end">
              <TertiaryButton
                className={"bg-slate-400 mr-2 text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Cancel"}
                onClick={() => {
                  setDisableModal(false)
                }}
              />
              <TertiaryButton
                className={"bg-red text-white p-2 w-fit max-w-[130px] mt-2"}
                content={"Yes, I'm sure"}
                onClick={() => {

                  let result;
                  changeStatus(idUser, isDisabled).then(response => {
                    result = response;
                  }
                  );
                  setTimeout(() => {
                    setIsDisabled(result);
                  }, 1000);
                  setDisableModal(false);
                }}
              />
            </div>
          </>
        }
        status={disableModal}
        onClose={() => setDisableModal(false)}
      />
      <Modal
        externalControl
        status={viewModal}
        title={"User details"}
        className={"w-[600px]"}
        body={
          <UserView
            selectedUser={userSelected}
            onClose={() => setViewModal(false)}
          />
        }
        onClose={() => setViewModal(false)}
      />
      <Modal
        externalControl
        title={"Edit " + (user.displayName ? user.displayName : '')}
        className={"w-[900px]"}
        body={
          <UserManager
            userInfo={userToModify}
            onComplete={() => setEditModal(false)}
            onClose={() => setEditModal(false)}
          />
        }
        status={editModal}
        onClose={() => setEditModal(false)}
      />
    </>
  );
};

export default UserRow;
