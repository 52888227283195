import React, { useEffect } from "react";
import {
  HiOutlineChartBar,
  HiOutlineCash,
  HiOutlineTicket,
  HiOutlineCog,
  HiUserGroup,
  HiOutlineFilter,
  HiOutlineQuestionMarkCircle
} from "react-icons/hi";
import { AiOutlineHome } from "react-icons/ai";

import { Link } from "react-router-dom";
import { BiLock, BiUserCircle } from "react-icons/bi";
import { useMatch } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { UilHouseUser } from "@iconscout/react-unicons";
import { CiBoxes } from "react-icons/ci";

const menu = [
  {
    name: "Users",
    href: "users",
    show: true,
    active: false,
    icon: <HiUserGroup className={"w-6 h-auto hover:text-blue"} />,
  },
  
];

const settingsMenu = [
  {
    name: "General",
    href: "settings",
    show: false,
    active: false,
    icon: <BiUserCircle className={"w-6 h-auto hover:text-blue"} />,
  },
  {
    name: "Security",
    href: "settings/security",
    show: true,
    active: false,
    icon: <BiLock className={"w-6 h-auto hover:text-blue"} />,
  },  
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function SideBar({ className, setIsOpen }) {
  const locationSettings = useMatch("dashboard/settings/*");
  const locationDashboard = useMatch("dashboard/*");
  useEffect(() => {
    if (!locationSettings) {
      menu.forEach((element) => {
        if (element.href === locationDashboard.params["*"]) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    } else {
      settingsMenu.forEach((element) => {
        if (element.href === locationDashboard.params["*"]) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    }
  });

  return (
    <div
      className={
        className + " flex-col pr-5 border-r-2 h-screen bg-neutral-50 flex"
      }
    >
      <div className="flex flex-col items-start h-full my-5 text-blue">
        <Link
          className="flex items-center justify-center w-full h-2 my-10"
          to="/dashboard"
        >
          <img
            src="/Logo-Petroil.png"
            className="items-center h-[5rem] pt-3 pb-4"
            alt="logo"
          />
        </Link>
        <div className="h-full">
          <div className="mt-10 space-y-5 items-center" id="selectionmenu">
            {locationSettings == null
              ? menu.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(
                        item.active ? "border-l-4" : "border-0",
                        "flex flex-row items-center border-petroil"
                      )}
                    >
                      <div className="flex items-center mx-12">
                        {item.icon}
                        <Link
                          to={item.href}
                          onClick={() => {
                            menu.forEach((element) => (element.active = false));
                            item.active = true;
                            if (window.innerWidth < 1200) setIsOpen(false);
                          }}
                          className={classNames(
                            item.active
                              ? "font-bold"
                              : "font-semibold opacity-80",
                            "flex ml-2 cursor-pointer hover:text-blue hover:opacity-100 text-lg"
                          )}
                        >
                          {item.name}
                        </Link>
                      </div>
                    </div>
                  );
                })
              : settingsMenu.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(
                        item.active ? "border-l-4" : "border-0",
                        "flex flex-row items-center border-petroil"
                      )}
                    >
                      <div className="flex items-center mx-12">
                        {item.icon}
                        <Link
                          to={item.href}
                          onClick={() => {
                            settingsMenu.forEach(
                              (element) => (element.active = false)
                            );
                            item.active = true;
                            if (window.innerWidth < 1200) setIsOpen(false);
                          }}
                          className={classNames(
                            item.active ? "font-bold" : "font-semibold",
                            "flex ml-2 cursor-pointer hover:text-petroil/80 text-lg"
                          )}
                        >
                          {item.name}
                        </Link>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        <div className="flex items-center justify-start w-full mx-8 mb-5">
          {!locationSettings && (
            <>
              <HiOutlineCog className="w-8 h-auto mr-2" />
              <Link
                to={"settings"}
                className="text-lg font-semibold cursor-pointer hover:text-petroil/80"
                onClick={() => {
                  settingsMenu.forEach((element) => {
                    element.href == "settings"
                      ? (element.active = true)
                      : (element.active = false);
                  });
                }}
              >
                Settings
              </Link>
            </>
          )}
          {locationSettings && (
            <>
              <IoChevronBack className="w-8 h-auto mr-2" />
              <Link
                to={""}
                onClick={() => {
                  menu.forEach((element) => {
                    element.href == ""
                      ? (element.active = true)
                      : (element.active = false);
                  });
                }}
                className="text-lg font-semibold cursor-pointer hover:text-petroil/80"
              >
                Go Back
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
