import { BurgerSidebar } from "./burger-sidebar";
import { SideBar } from "./sidebar";

export const SideBarComponent = () => {
  return (
    <>
      {
        <div className="top-0 hidden h-fit big-screen:flex">
          <SideBar className={"w-[15rem]"} />
        </div>
      }
      {<BurgerSidebar />}
    </>
  );
};
