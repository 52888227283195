import React from "react";
import { TextFieldImaged } from "./text-field-image";
import { MdAttachMoney } from "react-icons/md";
import { BiEuro } from "react-icons/bi";

export const CurrencyField = ({
  onChange,
  inputClassName,
  label,
  className,
  labelClassName,
  placeholder,
  value
}) => {
  return (
    <TextFieldImaged
      id="displayNameField"
      inputClassName={inputClassName + " " }
      label={label}
      className={className + " " + ""}
      labelClassName={labelClassName + " " + ""}
      placeholder={placeholder}
      type="number"
      min="0.00"
      max="10000.00"
      step="0.01"
      value={value}
      onChange={onChange}
      image={<BiEuro className="w-5 h-auto ml-1" />}
    />
  );
};
