import React, { useContext, useEffect, useState } from "react";
import UserRow from "../../components/table/table";
import { DataContext } from "../index";



function Users() {

  const context = useContext(DataContext);



  return (
      <div className="w-full">
        <h1 className="text-3xl font-bold">Users</h1>
        <div className=" mt-[100px]">
          <table className="mx-auto bg-white rounded-lg shadow-md p-5">
            <thead className="">
              <tr className="border-b cursor-pointer hover:bg-neutral-100 p-5">
                <th className="p-4">Name</th>
                <th className="p-4">Email</th>
                <th className="p-4">Phone number</th>
                <th className="p-4">Creation date</th>
                <th className="p-4">Last login</th>
                <th className="p-4">Organization</th>
                <th className="p-4">Options</th>
              </tr>
            </thead>
            <tbody className="">
              {context.users && context.users.map((user, index) => (
                <UserRow
                  key={index}
                  user={user}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
  );
}

export default Users;
