import React, { createContext, useEffect, useState } from "react";
import { SideBarComponent } from "../components/sidebar/sidebar-component";
import UserSecurity from "./settings/security";
import UserSettings from "./settings/settings";
import LoadingApp from "./shared/LoadingApp";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import NotFound from "./shared/notFound";
import Users from "./users";
import Home from "./home";
import { getAllUsersInformation } from "../utilities/functions";

export const DataContext = createContext({
  loading: null,
  users: null
});

function Index() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();

useEffect(() => {

 // Fetch all data informations
  const fetchData = () => {
    setLoading(true);
    getAllUsersInformation().then(result => {
      setUsers(result);
    });

    setLoading(false);
  };

  
  fetchData();
}, []);



const onChangeUpdate = async (key) => {
  switch (key) {
    case "users":
      getAllUsersInformation().then(result => {
        setUsers(result);
      });
      break;
  }
};

    return (
      <DataContext.Provider
      value={{
        loading: loading,
        users: users,
        updateData: onChangeUpdate
      }}
    >

     <div className="flex">
        <SideBarComponent />
        <div className="flex items-start justify-start w-full min-h-full p-10 bg-gray-100 text-blue">
          {!loading ? (

            <Routes>
              <Route path="settings" element={ <UserSettings />} />
              <Route path="users" element={<Users />} />
              <Route path="/" element={<Home />} />
              <Route path="settings/security" element={<UserSecurity />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : (
            <LoadingApp />
          )}
        </div>
      </div>
    </DataContext.Provider>
    );
  }

  export default Index;
