import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
const apiUrl = process.env.REACT_APP_API_BASE_URL;


const changeStatus = async (userId, status) => {
  if (!userId) {
    toast.error("User is not defined");
    return;
  }

  const token = await getAuth().currentUser.getIdToken(true);
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}changeStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': "application/json",
        'Authorization': `Bearer ${token}`
      },
      // Reverse user status
      body: JSON.stringify({
        id: userId,
        status: !status
      })
    })
      .then(async response => { return await response.json() })
      .then(async data => {
        if (data) {
          toast.success(`User has been ` + (!data.status ? "enabled." : "disabled."));
          resolve(await data.status);
        } else {
          toast.error("The request failed.");
        }
      })
      .catch((error) => {
        toast.error('An error occurred while changing the account status.');
        reject(error);
      });
  });
}

const updateOrganization = async (userId, organizationInfo) => {

  if (!userId) {
    toast.error("User not defined");
    return;
  }

  if (!organizationInfo) {
    toast.error("Organization info not defined");
    return;
  }
  const token = await getAuth().currentUser.getIdToken(true);
  fetch(`${apiUrl}updateOrganization`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      id: userId,
      organizationInfo: organizationInfo
    })
  })
  .then(async response => { return await response.json() })
  .then(toast.success("Organization info updated!"));
}

const deleteUser = async (idUser, idOrganization) => {
  try {
    const token = await getAuth().currentUser.getIdToken(true);
    fetch(`${apiUrl}deleteUser`, {
      method: 'POST',
      headers: {
        'Content-Type': "application/json",
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        idUser: idUser,
        idOrganization: idOrganization
      })
    })
      .success(async response => {
        await response;
        if (response.ok) {
          toast.success('The data were successfully deleted.');
          return response.json();
        } else {
          toast.error("Error during the delete of organization");
        }
      })
      .catch(error => {
        toast.error('Error: ', error);
        toast.error('An error occurred while changing the account status.');
      })
  } catch (error) {
    toast.error('Error while deleting data: ', error);
  }
}

const disableAccount = async (userId) => {
  // Disabilita l'account utente utilizzando Cloud Function o API backend
  const token = await getAuth().currentUser.getIdToken(true);
  fetch(`disableAccount`, {
    method: 'POST',
    headers: {
      'Content-Type': "application/json",
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      userId: userId,
    })
  })
  .then(response => {
    if (response.ok) {
      toast.success('Account successfully disabled!');
    } else {
      throw new Error('Error while disabling account.');
    }
  })
  .catch(error => {
    console.error('Errore:', error);
    toast.error('Error while disabling account.');
  });
};

const getOrganizationById = async (idOrganization) => {
  const token = await getAuth().currentUser.getIdToken(true);
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}getOrganizationById`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        idOrganization: idOrganization
      })
    })
      .then(async response => { return await response.json() })
      .then(async data => {
        resolve(await data);
      })
      .catch(error => {
        console.error("Error during the retrieve of user: ", error);
        reject(error);
      });
  });
};

const getAllUsersInformation = async () => {
  try {
    const token = await getAuth().currentUser.getIdToken(true);
    const response = await fetch(`${apiUrl}getAllUsersInformation`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    return await response.json();
  } catch (error) {
    throw (error);
  }
}

export { deleteUser, changeStatus, getOrganizationById, getAllUsersInformation, updateOrganization, disableAccount };