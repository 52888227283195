import React, { useState } from "react";
import { BiAlarm, BiEraser } from "react-icons/bi";
import { PasswordField } from "../../components/input/fields/password-field";
import TextField from "../../components/input/fields/text-field";
import { FcGoogle } from "react-icons/fc";
import SecondaryButton from "../../components/buttons/secondary-button";
import {
  getAuth,
  GoogleAuthProvider,
  updatePassword,
  linkWithPopup,
} from "firebase/auth";
import { removeUser } from "../../utilities/firebase/firestore-functions";
import { toast } from "react-toastify";

function UserSecurity() {
  const auth = getAuth();
  const user = auth.currentUser;
  const googleProvider = new GoogleAuthProvider();

  let isGoogleLinked = false;
  user.providerData.forEach((userInfo) => {
    if (userInfo.providerId == "google.com") {
      isGoogleLinked = true;
      return;
    }
  });

  const [passw1, setPassw1] = useState("");
  const [passw2, setPassw2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(0);
  let regexPassword =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

  return (
    <div className="flex flex-col content-between ml-10 w-[45rem] h-full mr-10">
      
      <div className="text-4xl font-bold ">User Security</div>
      <div className="my-5 text-2xl font-normal">General</div>
      <hr className="w-full mb-6" />
      <div className="flex flex-col ">
        <div className="items-center">
          <div className="big-screen:hidden font-bold mb-5 ml-[10px]">
            Change password
          </div>
          <div className="flex flex-row mb-[4rem] justify-center big-screen:justify-start">
            <div className="flex flex-col break-words">
              <p className="w-[13rem] hidden big-screen:flex ">
                Change password
              </p>
            </div>

            {/* Card providers */}
            <div className="min-w-[23rem] max-w-[40rem] w-full ">
              <div className="h-fit border-2 rounded-lg ml-[10px] pb-6 bg-neutral-50">
                <div className="flex flex-col mx-6 mt-6">
                  Password
                  <div className="max-w-[85%] min-w-[60%] h-10 ">
                    <PasswordField
                      inputClassName="p-1 my-1 passwfield"
                      value={passw1}
                      onChange={(e) => setPassw1(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col mx-6 mt-6">
                  Re-enter Password
                  <div className="max-w-[85%] min-w-[60%] h-10">
                    <PasswordField
                      inputClassName="p-1 my-1 passwfield"
                      value={passw2}
                      onChange={(e) => setPassw2(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex mx-6 mt-6">
                  <SecondaryButton
                    content="Change password"
                    className="w-[47%] h-[2.6rem] min-w-[14rem] max-w-[17rem] text-sm"
                    onClick={(e) => {
                      //check regex
                      if (passw1 !== passw2) {
                        setErrorMessage("Password mismatch");
                      } else if (
                        !regexPassword.test(passw1) ||
                        !regexPassword.test(passw2)
                      ) {
                        setErrorMessage(
                          "Password must include at least: 8 charachters,  1 uppercase letter, 1 lowercase letter, 1 number, 1 special chearacters"
                        );
                      } else {
                        setErrorMessage("");
                        setSuccess(1);
                        // change password

                        updatePassword(user, passw1)
                          .then(() => {
                            toast.success("Password updated!");
                          })
                          .catch((error) => {
                            toast.error("An error occurred: " + error);
                          });
                      }
                    }}
                  />
                </div>
                {errorMessage !== "" && (
                  <p className="px-6 mt-4 text-xl text-red">{errorMessage}</p>
                )}
                {success == 1 && (
                  <p className="px-6 mt-4 text-xl text-petroil">
                    Password changed successfully
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSecurity;
