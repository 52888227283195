import { Route, Routes, useMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Login } from "./pages/auth/login";
import NotFound from "./pages/shared/notFound";
import Dashboard, { DataContext } from "./pages/index";
import ExtraInfo from "./pages/auth/extra-info";

import "react-toastify/dist/ReactToastify.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "./Auth-app-wanager";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";

function App() {
  // Get user locally
  const context = useContext(DataContext);
  const currentUser = useContext(UserContext);
  // Dynamic dashboard
  const location = useMatch("dashboard/*");
  const startLocation = useMatch("/");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#c3f73a",
      },
      secondary: {
        main: "#0a6d67",
      },
    },
  });
  /* Check if exist organization ID */

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="min-h-[80vh]">
          <Routes>
          <Route path="" element={startLocation && !location ? currentUser ? window.location.replace('/dashboard') : window.location.replace('login') : <NotFound />} />
            <Route path="login" element={<Login />} />
            <Route
              path="dashboard/*"
              element={ currentUser && !currentUser.phoneNumber ? 
                <ExtraInfo/>
               : <Dashboard />}
            />
            
             <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          
        </div>
         

      </ThemeProvider>
    </>
  );
}

export default App;
