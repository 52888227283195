import React, { createContext, useEffect, useState } from "react";

function Home() {
  return (
    <div className="flex min-h-full w-full items-center">
      <div className="flex flex-wrap flex-col items-center justify-center mx-auto text-xl font-semibold min-h-full min-w-[800px]">
        <h2 className="text-4xl mb-8 font-bold">Welcome to BiLemon!</h2>
      </div>
    </div>
  );
}

export default Home;