import LoadingApp from "./pages/shared/LoadingApp";
import { createContext, useState } from "react";
import { getAuth } from "firebase/auth";
import App from "./App";
import { useNavigate } from "react-router-dom";

export const UserContext = createContext();

export const AuthAppWrapper = () => {
  const [userState, setUserState] = useState("Unknown");
  getAuth().onAuthStateChanged((user) => {
    if (user) {
      if (user != userState) 
      setUserState(user);
    } else {
      if (userState != null) {
        setUserState(null);
      }
    }
  });

  return (
    <UserContext.Provider value={userState}>
      {userState === "Unknown" ? <LoadingApp /> : <App />}
    </UserContext.Provider>
  );
};
